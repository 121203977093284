<template>
<div>



  <v-container class="text-center" grid-list-xs>

    <v-alert v-if="err"
      outlined
      text
      type="error"
      > {{msg}} </v-alert>

    <h1 class="ma-5">Welcome to the Minemegle website</h1>
    <p>Here you can signin with google and generate a token that needs to be placed in the config</p>
    <router-link to="/privacy">Privacy policy</router-link>

  </v-container>
  <v-divider></v-divider>



  <v-container d-flex justify-center >

    <v-btn
    elevation="2"
    x-large
    :href="'https://api.nolz.dev/api/auth/google'"
    color="primary">
      Signin with google <v-icon>mdi-google</v-icon>
    </v-btn>


  </v-container>

</div>
</template>

<script>
  export default {
    name: 'Home',
    title: 'Minemegle',
    data() {
      return {
        err: false,
        msg: '',
        api: 'https://api.nolz.dev'
      }
    },
    components: {

    },
    created () {
      if(this.$route.query.error != undefined) {
        this.err = true;
        //console.log(this.$route.query.error);
        this.msg = this.$route.query.error;
      }
    }
  }
</script>

<template>
  <div>

    <v-container grid-list-xs>
      <h1>Privacy</h1>
      <p>We do not store any personal information, only your google id to prevent massive amounts of bots.</p>
    </v-container>

  </div>
</template>

<script>
export default {
    name: 'Privacy'
}
</script>

<style>

</style>
<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="40"
        />

        <h1>MINEMEGLE</h1>

        <v-spacer class="ma-5"></v-spacer>

        <v-btn to="/" text>
        <span class="mr-2">Home</span>
        </v-btn>

        <v-spacer class="ma-5"></v-spacer>

        <v-btn text to="/dashboard">
        <span class="mr-2">Dashboard</span>
        </v-btn>



      </div>

      <v-spacer></v-spacer>

      <v-btn
        href=""
        target="_blank"
        text
      >
        <span class="mr-2">Minehut page</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

};
</script>
